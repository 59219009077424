<template>
  <main>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Статистика"
    />
    <div style="margin: 0px 20px">
      <a-button
        type="primary"
        style="margin-bottom: 10px"
        @click="globalUpdateData"
        >Обновить статистику</a-button
      >
      <div id="totalUsers" v-if="totalUsers">
        Общее количество пользователей: {{ totalUsers }}
      </div>
      <br />
      <a-tabs default-active-key="1" v-model="activeKey" @change="callback">
        <a-tab-pane
          key="1"
          tab="Количество пользоватей по диагнозам"
        ></a-tab-pane>
        <a-tab-pane
          key="2"
          tab="Количество пользоватей по регионам"
        ></a-tab-pane>
      </a-tabs>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      totalUsers: null,
      activeKey: 1,
    };
  },
  created() {
    this.getTotalUsers();
    if (this.$router.history.current.name == "statistics.regions")
      this.activeKey = 2;
    else if (this.$router.history.current.name == "statistics.diagnoses")
      this.activeKey = 1;
    else this.activeKey = 1;
    // console.log(this.activeKey)
  },
  methods: {
    callback(key) {
      if (key == 1) this.$router.push("diagnoses");
      else this.$router.push("regions");
    },
    getTotalUsers() {
      axios
        .get("/cp/users/statistics/")
        .then((response) => {
          this.totalUsers = response.data.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalUpdateData() {
      this.$router.go(this.$router.currentRoute);
      this.getTotalUsers();
    },
  },
};
</script>
